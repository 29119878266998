import React from "react";
import BreadCrumb from "Common/BreadCrumb";

// Icons
import { Search } from "lucide-react";

import { ToastContainer } from "react-toastify";
import useActivities from "hooks/useActivities";

const Activities = () => {
  const { data, isLoading, hasNextPage, handleEndReached } = useActivities();

  return (
    <React.Fragment>
      <BreadCrumb title="Activity Logs" pageTitle="Activity Logs" />

      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="ltr:text-left rtl:text-right ">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold">Admin</th>

                    <th className="px-3.5 py-2.5 font-semibold  border-custom-500 dark:border-custom-800">
                      Activity
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold  border-custom-500 dark:border-custom-800">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {data?.length! < 1 ? (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                      </div>
                    </div>
                  ) : (
                    <>
                      {data?.map((activity) => (
                        <tr key={activity._id} className=" cursor-pointer">
                          <td className="px-3.5 py-2.5  border-custom-500 dark:border-custom-800">
                            <div className="flex items-center gap-2">
                              <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600"></div>
                              <div className="grow">
                                <h6 className="mb-1">
                                  {activity?.user?.firstName}{" "}
                                  {activity?.user?.lastName}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td className="px-3.5 py-2.5  border-custom-500 dark:border-custom-800">
                            {activity.activity}
                          </td>

                          <td className="px-3.5 py-2.5  border-custom-500 dark:border-custom-800">
                            {new Date(activity.createdAt).toLocaleDateString()}{" "}
                            {new Date(activity.createdAt).toLocaleTimeString()}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              <div className="flex gap-4 items-center justify-end my-4 px-4">
                {hasNextPage && (
                  <button
                    className=" text-custom-500"
                    onClick={() => handleEndReached()}
                  >
                    Load more
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Activities;
