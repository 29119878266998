import {
  Role,
  ServiceCategory,
  Skill,
  SkillCreate,
} from "types/general.interface";
import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePutRequest,
} from "./http.service";

export const getUserRoles = async () => {
  return await handleGetRequest<Role[]>("/admins/roles");
};

export const getServiceCategories = async () => {
  return await handleGetRequest<ServiceCategory[]>("/admins/service-category");
};

export const addServiceCategories = async (serviceCat: ServiceCategory) => {
  return await handlePostRequest<ServiceCategory, null>(
    "/admins/service-category",
    serviceCat
  );
};
export const getSkills = async (serviceCategory: string) => {
  return await handleGetRequest<Skill[]>(`/admins/skills/any`);
};

export const addSkill = async (payload: SkillCreate) => {
  return await handlePostRequest<SkillCreate, null>("/admins/skills", payload);
};

export const deleteSkill = async (id: string) => {
  return await handleDeleteRequest<null>(`/admins/skills/${id}`);
};

export const addRole = async (payload: Role) => {
  return await handlePostRequest<Role, null>("/admins/roles", payload);
};
export const deleteRole = async (id: string) => {
  return await handleDeleteRequest<null>(`/admins/roles/${id}`);
};

export const editRole = async (id: string, plan: Role) => {
  return await handlePutRequest<Role, null>(`/admins/roles/${id}`, plan);
};

export const deleteCategory = async (id: string) => {
  return await handleDeleteRequest<null>(`/admins/service-category/${id}`);
};

export const editCategory = async (id: string, record: ServiceCategory) => {
  return await handlePutRequest<ServiceCategory, null>(
    `/admins/service-category/${id}`,
    record
  );
};

export const editSkill = async (id: string, record: SkillCreate) => {
  return await handlePutRequest<SkillCreate, null>(
    `/admins/skills/${id}`,
    record
  );
};
