export const IDENTITY_DOCUMENT_TYPES = [
  {
    label: "Drivers License",
    value: "Drivers License",
  },
  {
    label: "National Identity Card",
    value: "NIN",
  },
  {
    label: "Voters Card",
    value: "Voters Card",
  },
  // {
  //   label: 'International Passport',
  //   value: 'International Passport',
  // },
];
