import {
  ActivityIcon,
  Award,
  BadgeCheck,
  BellIcon,
  CalendarDays,
  ChefHatIcon,
  CircuitBoard,
  Codesandbox,
  FileArchiveIcon,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  NotebookIcon,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  SettingsIcon,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  User2,
  UserRound,
} from "lucide-react";

export const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/#",
    icon: <MonitorDot />,
    subItems: [],
  },

  {
    label: "Apps",
    isTitle: true,
  },
  {
    id: "chat",
    label: "Users",
    icon: <User2 />,
    link: "/apps-users-list",
    parentId: 2,
  },
  {
    id: "bookings",
    label: "Bookings",
    link: "/bookings",
    icon: <NotebookIcon />,
  },
  {
    id: "email",
    label: "roles",
    icon: <Mail />,
    link: "/roles",
    parentId: 2,
  },
  {
    id: "calendar",
    label: "Service Category",
    link: "/service-category",
    icon: <ChefHatIcon />,
  },
  {
    id: "skills",
    label: "Skills",
    link: "/skills",
    icon: <FileArchiveIcon />,
  },
  {
    id: "subscription",
    label: "Subscription Plans",
    link: "/subscription-plans",
    icon: <ShoppingBag />,
  },
  {
    id: "servicePlans",
    label: "Service Plans",
    link: "/service-plans",
    icon: <SettingsIcon />,
  },
  {
    id: "job",
    label: "Job Plans",
    link: "/job-plans",
    icon: <BadgeCheck />,
  },
  {
    id: "notifications",
    label: "Notifications",
    link: "/notifications",
    icon: <BellIcon />,
  },
  {
    id: "actitvity",
    label: "Activity Logs",
    link: "/activities",
    icon: <ActivityIcon />,
  },
];
